import axios from 'axios';
import exportValue from '../../apiconfig';
//import { CountryLists } from './LocationActions';
import { DASHBOARD_TOTAL_COUNT , COUNT_TRANSPORTLIST, USERS_FIND_COUNT, COUNT_SHIPMENT,COUNT_VEHICLE_LIST} from "../constants/types";

// import { toast } from 'react-toastify';

// import 'react-toastify/dist/ReactToastify.css';


export const DashboardLists = (data) => (dispatch) => {
    console.log("hello is hello",data);
   
    let dashboard_data = {};
    
      axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/dashboard_api`,  dashboard_data , { headers: exportValue.headers }).then((res) => {
        console.log("fjh",res); 
        if(res.data.users_find_count != undefined){
            let count_detail = {users_find_count:res.data.users_find_count,count_vehicle_lists:res.data.count_vehicle_lists,count_transporterList:res.data.count_transporterList,count_shipment:res.data.count_shipment,admin:res.data.admin,count_shipment_bids:res.data.count_shipment_bids,mon_shipment:res.data.mon_shipment , tue_shipment:res.data.tue_shipment,wed_shipment:res.data.wed_shipment,thu_shipment:res.data.thu_shipment,fri_shipment:res.data.fri_shipment,sat_shipment:res.data.sat_shipment,sun_shipment:res.data.sun_shipment,count_spamReport:res.data.count_spamReport,mon_users:res.data.mon_users,tue_users:res.data.tue_users,wed_users:res.data.wed_users,thu_users:res.data.thu_users,fri_users:res.data.fri_users,sat_users:res.data.sat_users,sun_users:res.data.sun_users,mon_payments:res.data.mon_payments,tue_payments:res.data.tue_payments,wed_payments:res.data.wed_payments,thu_payments:res.data.thu_payments,fri_payments:res.data.fri_payments,sat_payments:res.data.sat_payments,sun_payments:res.data.sun_payments,week_amount:res.data.week_amount}
           
            dispatch({
                type: DASHBOARD_TOTAL_COUNT, 
                payload: count_detail,
            })
            
            
            console.log( "hb",count_detail)
        }
   
   
    
       }).catch((e)=>{      
        //toast.configure()
        //toast.error("Some thing went wrong")  
        console.log("----error:   ",e);
    })
}
 
