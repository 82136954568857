import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import { Navigate, useNavigate } from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination';
import { NumericFormat } from 'react-number-format';

const Add_product = () => {
  const Cod = exportValue.COD_COMISSION ;

    let navigate=useNavigate()
    const [carrierList, setcarrierList] = useState([]);
    const[state,setState] = useState({product_name:"",carrier_id:"",mode:"",volumetric_weight:0,max_weight:0 , additional_weight:0,product_type:"" , parcel_type:"" , cod_limit:0 , 
     cod_percentage:0 , cod_amount:0
  })
  const [selectedType, setSelectedType] = useState('B2C');
    const[domesticmode,setdomesticmodeState] = useState({domestic:false})
    const[internationalmode,setinternationalmodeState] = useState({international:false})
    const[parceltype,setparceltypeState] = useState({parcel:false})
    const[documenttype,setdocumenttypeState] = useState({document:false})
    const [Errorstate,setErrorState]=useState({err:""})
     const[producttype , setproducttypeList] = useState([])
     const[carriertype , setcarriertypeList] = useState([])

    const [rowstate, setRowState] = React.useState([{ start_range:'0.00',end_range:'',buttonType:"add",error:{},err:{},err1:{} , point:""}])
    console.log("rowstate",rowstate)
    const[errstate , seterrstate ]= useState({err:""})
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    const homeSection = document.querySelector('.home-section');
    if (homeSection) {
        homeSection.classList.toggle('sidebar-closed');
    }
};
    useEffect(() => {
        getCarrierList();
    }, [])
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
      })
    const getCarrierList = (index=0) => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/product_carrier_list`;
        let sendData = {  dstatus:1,
           
           };
       
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
          
            setcarrierList(res.data.output)


        }).catch((e) => {


        });
    }
    const getProductTypeList = (carrier_id) => {


      let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/product_type`;
      let sendData = {  carrier_id:carrier_id
         
         };
         console.log("senddata",sendData)
     
      axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
          console.log("res ", res);
        
          setproducttypeList(res.data.output)


      }).catch((e) => {


      });
  }

  const getcarrierType =(carrier_id)=>{
    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/carrier_type_find`;
    let sendData = {  carrier_id:carrier_id
       
       };
       console.log("senddata",sendData)
   
    axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
        console.log("res setcarriertypeList", res);
      
        setcarriertypeList(res.data.output)


    }).catch((e) => {


    });
  }

    const handleChange=(e)=>{
        // console.log("namess",e.target.name)
        // console.log("value",e.target.value)
        
        let names = e.target.name
        let value= e.target.value
       
        if(e.target.type=="text"){
        setState({...state,[e.target.name]:value})
        }
        else if(e.target.type=="radio"){
            if(e.target.checked==true){
                if(e.target.name=="mode_domestic"){
                    setdomesticmodeState({...domesticmode,domestic:true})
                    setinternationalmodeState({...internationalmode,international:false})
                    setState({...state,mode:"Domestic"})
                }
                else if(e.target.name=="mode_international"){
                    setdomesticmodeState({...domesticmode,domestic:false})
                    setinternationalmodeState({...internationalmode,international:true})
                    setState({...state,mode:"International"})
                }
                else if(e.target.name=="document"){
                  setparceltypeState({...parceltype,parcel:false})
                  setdocumenttypeState({...documenttype,document:true})
                  setState({...state,parcel_type:"Document"})
                }
                else{
                  setparceltypeState({...parceltype,parcel:true})
                  setdocumenttypeState({...documenttype,document:false})
                  setState({...state,parcel_type:"Parcel"})
                }
            }
        }
        else if(e.target.type=="number"){
          setState({...state,[e.target.name]:value})
        }
        else{
            setState({...state,[e.target.name]:value})
            getProductTypeList(e.target.value)
            if(e.target.name == "carrier_id"){
              getcarrierType(e.target.value)
           }
        }
    }

    const handleChange1 =(e)=>{
 setState({...state , product_type:e.target.value})
    }

    function addRows(type, index) {
        console.log("type    ", type, " . ", index)

        if(rowstate.length==1){
          console.log("index====>" ,index)

        }
        else{
          console.log("index====>" ,index-1)

        }
        
        let items = [...rowstate];
        console.log("items    ", items)
        if (type == 1) {
          const decimalCount = ()=> {
            if(rowstate.length==1){
              let num= rowstate[index].end_range
              // Convert to String
              const numStr = (num);
              // String Contains Decimal
              if (numStr.includes('.')) {
                 return numStr.split('.')[1].length;
              };
            }
            else{
              let num= rowstate[index-1].end_range
              // Convert to String
              const numStr = (num);
              // String Contains Decimal
              if (numStr.includes('.')) {
                 return numStr.split('.')[1].length;
              };
            }
           
            // String Does Not Contain Decimal
            return 0;
         }

         let start = 0 
           if(rowstate.length==1){
            let numb = (rowstate[index].end_range);
            let count = decimalCount(numb);
            start=  ((numb * 10 ** count) + 1) / 10 ** count
            console.log("start",start)
           }
           else{
            let numb = (rowstate[index-1].end_range);
            let count = decimalCount(numb);
            start= ((numb * 10 ** count) + 1) / 10 ** count
            console.log("start---->",start)

           }
        
        //  console.log(((numb * 10 ** count) + 1) / 10 ** count);
        
          let newItem = { start_range:String(start),end_range:'', buttonType: "remove",error:{} , err:{},err1:{},point:"" };
          console.log("newItem  =========>   ", newItem)
    
    
    
    
          //for (var i = 0; i <= rowstate.length; i++) {
    
          items.push(newItem);
          //}
          console.log("items  =========>   ", items)
    
          setRowState(items)
        } else if (type == 2) {
          items.splice(index, 1);
          //const newPeople = items.filter((person,index) => index == index);
          setRowState(items)
        }
      }
    const saveNewProduct= (index=0) => {
      
      
        let items = [...rowstate];
        
        let flag = true;
        let fl=true
       
       
        items.map((val,index)=>{
         // console.log("val=> ",val);
         val.error = {};
          if(val.start_range == "") {
            val.error["start_range"] = true;     
            flag = false;   
          }
          if(val.end_range == "") {
            val.error["end_range"] = true;     
            flag = false;     
          }
          val.err = {};
          var str1 = val.end_range;
          var result1 = str1.split(".").length>1
          //  console.log("result======> ",str1.split(".").length>1)
           if (!result1){
            val.err["end_range"] = true;     
              fl=false
           } 
           var str1 = val.start_range;
          var result1 = str1.split(".").length>1
          //  console.log("result======> ",str1.split(".").length>1)
           if (!result1){
            val.err["start_range"] = true;     
              fl=false
           } 

           if( Number(val.start_range) > Number(val.end_range)){
            val.err["rate_error"] = true;     
              fl=false
           }
         
      
       
         
          console.log("val=> ",val);
        })
        // console.log("rt------>",rt)
        setRowState(items)
     
      
      
        let itemsR = {...state};
        itemsR.error = {};
        if (itemsR.product_name == "") {
          itemsR.error["product_name"] = true;   
          
        }
        if(itemsR.mode == ""){
          itemsR.error["mode"] = true; 
           
        }
        // if(itemsR.volumetric_weight == ""){
        //     itemsR.error["volumetric_weight"] = true; 
             
        //   }
          if(itemsR.max_weight == ""){
            itemsR.error["max_weight"] = true; 
             
          } if(itemsR.carrier_id == ""){
            itemsR.error["carrier_id"] = true; 
             
          }
          if(itemsR.parcel_type == ""){
            itemsR.error["parcel_type"] = true; 
             
          }
          if(itemsR.cod_limit == ""){
            itemsR.error["cod_limit"] = true; 
             
          }

          if(producttype.length>0){
            if(itemsR.product_type==""){
              itemsR.error["product_type"] = true;
            }
          }
          else{
            itemsR.product_type=""
          }
         
        setState(itemsR)

       
setTimeout(()=>{


        console.log("R =>",itemsR);
        console.log("R length =>",Object.keys(itemsR.error).length);
        console.log("flag -=== " , state)
        if(Object.keys(itemsR.error).length == 0  && flag  ){
           if(fl){
            
          console.log("flag -=== ")
            if(state.carrier_id!=""&&state.product_name!=""&&state.max_weight!=""&& state.mode!="" &&
            (Cod ? state.cod_amount != "" && state.cod_percentage != "" : true)
             ){
            
          let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/carrier_product_add_api`;
        let sendData = {  product_name:state.product_name,mode:state.mode,volumetric_weight:state.volumetric_weight,max_weight:state.max_weight,carrier_id:state.carrier_id ,product_type:state.product_type, weight_range:rowstate , additional_weight:state.additional_weight , cod_limit:state.cod_limit , parcel_type:state.parcel_type ,
          ...(Cod ? { cod_percentage: state.cod_percentage } : {}), 
          ...(Cod?{cod_amount:state.cod_amount}:{})
           
           };
           console.log("sendData",sendData)
       
        axios.post( full_api,sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
          
              navigate('/product')


        }).catch((e) => {


        });
    
            }else {      
        
                Toast.fire({
                  background:"rgb(231, 91, 75)",
                  type: 'unsuccess',
                  title: "Please fill all the Details",
                  color:"white"
                });
                console.log("----error:   ");
              }

             
            }else{
              // Toast.fire({
              //   background:"rgb(231, 91, 75)",
              //   type: 'unsuccess',
              //   title: "Please Set Weight Start Range Greater than your Last set Weight End Range  ",
              //   color:"white"
              // });
            }
            
           
        } else {      
        
            Toast.fire({
              background:"rgb(231, 91, 75)",
              type: 'unsuccess',
              title: "Please fill all the Details",
              color:"white"
            });
            console.log("----error:   ");
          }
        },1000)
    }
   

    const handleweightsetup = (e, index) => {
        let adminCList = [...rowstate]
        console.log("e =  ", e.target.name)
        console.log("value =  ", e.target.value)
        console.log("index =  ", index);
        console.log("adminCList =  ", adminCList[index]);
       
        adminCList[index][e.target.name] = (e.target.value);
        if (e.target.name == "end_range") {
         
          // var result = (  e.target.value - Math.floor(e.target.value )) !== 0; 
          // console.log("result",result) 
          // let flag= true
          // if (!result){
          //   adminCList[index].point = "Please Fill End Range in decimal"
          //   // setErrorState({err:"Please Fill End Range in decimal"})  
          //   // console.log("errror",Errorstate.err) 
          //  } 
          //  else{
          //   // console.log("ssssssssss")
          //   // setErrorState({err:""}) 
          //   adminCList[index].point = ""
          //  }
        
        const decimalCount = ()=> {
          let num= (e.target.value)
          // Convert to String
          const numStr = String(num);
          // String Contains Decimal
          if (numStr.includes('.')) {
             return numStr.split('.')[1].length;
          };
          // String Does Not Contain Decimal
          return 0;
       }
       
       let numb = (e.target.value);
       let count = decimalCount(numb);
       let start = ((numb * 10 ** count) + 1) / 10 ** count
       adminCList[index+1].start_range = String(start)
      //  console.log(((numb * 10 ** count) + 1) / 10 ** count);
      }
        // var oNumber = 1.34567 
        // var oDecimalCount = 5 
        
        // increaseNumber(oNumber, oDecimalCount){ 
        // oNumber += //increase the 5th(oDecimalCount) decimal place 
        // }
        setRowState(adminCList)
        // }
    }
    const handleTypeChange = (e) => {
      setSelectedType(e.target.value);
    };
  
  
  return (
    <div>
     <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/>
     
      <section>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="#">
                                    <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Products</li>
                        </ol>
                    </nav>
                    <h2 class="h4">Add Product</h2>
                </div>
                <div class="btn-toolbar mb-2 mb-md-0">
                    
                </div>
            </div>
            
    </section>

    <section>
        <div class="row">
            <div class="col-12 col-md">
                <div class="card h-100 ">
                  <div class="card-body">
                    <h5>Setup Weight Range</h5> {(errstate.err!="")?<>
                                 <i class="fa fa-exclamation-triangle" style={{color:"rgb(167, 37, 20)"}} aria-hidden="true"></i><small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                                 lineHeight: "1.66667",
                                 fontWeight: "400",
                                 letterSpacing: "0.05em",marginLeft:"4px"}}>{errstate.err}</small></>:""}
                        <div class="add_product">
                            <table class="table table-bordered">
                            <thead>
                                <tr>
                                <th>#</th>
                                <th>Start Range</th>
                                <th>End Range</th>
                                <th>Add Row</th>
                                </tr>
                            </thead>
                            {rowstate.map((sub,index)=>(
                            <tbody>
                                <tr>
                                <td>{index+1}</td>
                                <td>
                                    <input type="number" class="form-control" id="text" placeholder="Enter Start Range" name="start_range"  onChange={(e)=>handleweightsetup(e,index)} value={sub.start_range}/>
                                    {(sub.err.start_range)?<>
                                 <i class="fa fa-exclamation-triangle" style={{color:"rgb(167, 37, 20)"}} aria-hidden="true"></i><small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                                 lineHeight: "1.66667",
                                 fontWeight: "400",
                                 letterSpacing: "0.05em",marginLeft:"4px"}}>Please Fill Weight Start Range in decimal</small></>:""}
                                </td>
                                <td>
                                    <input type="number" class="form-control" id="text" name="end_range"  placeholder="Enter End Range" onChange={(e)=>handleweightsetup(e,index)} />
                                    {(sub.err.end_range)?<>
                                 <i class="fa fa-exclamation-triangle" style={{color:"rgb(167, 37, 20)"}} aria-hidden="true"></i><small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                                 lineHeight: "1.66667",
                                 fontWeight: "400",
                                 letterSpacing: "0.05em",marginLeft:"4px"}}>Please Fill Weight End Range in decimal</small></>:""}
                                 <div>
                                 {(sub.err.rate_error)?<>
                                 <i class="fa fa-exclamation-triangle" style={{color:"rgb(167, 37, 20)"}} aria-hidden="true"></i><small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                                 lineHeight: "1.66667",
                                 fontWeight: "400",
                                 letterSpacing: "0.05em",marginLeft:"4px"}}>Please Fill Weight End Range greater than Start Range</small></>:""}
                                 </div>
                                 
                                    
                                </td>
                                {sub.buttonType=="add"?
                                <td>
                                  {rowstate.length==1?
                                    <button type="button" class="btn btn-primary" onClick={() => addRows(1,index)}>Add</button>
                                    :
                                    <button type="button" class="btn btn-primary" onClick={() => addRows(1,rowstate.length)}>Add</button>
                                  }
                                </td>
                                : <td>
                                <button type="button" class="btn btn-primary" onClick={() => addRows(2,index)}>Remove</button>
                            </td>}
                                </tr>
                            </tbody>
                            ))}
                            </table>
                        </div>
                        <h5 className="mt-5">Additional Charges on Weight Upto</h5>
                        <div class="add_product">
                            <table class="table table-bordered">
                           <tbody>
                                <tr>
                               
                                <td>
                                    <input type="number" class="form-control" id="text" placeholder="Enter Weight" name="additional_weight"   onChange={(e)=>handleChange(e)}/>
                                </td>
                               
                                </tr>
                            </tbody>
                           
                            </table>
                        </div>
                  </div>
                </div>
                


            </div>
            
            <div class="col-12 col-md-4 ">
                <div class="card h-100 ">
                  <div class="card-body">

                  <div class="">
                        <div class="mb-3 mt-3">
                        <label for="Product Type  Name" class="form-label">Product  Name:</label>
                        <input type="text" class="form-control" id="Product   Name" placeholder="Enter Product   Name" name="product_name" required  onChange={(e)=>handleChange(e)}/>
                        
                        </div>

                        <div class="mb-3 mt-3">
                        <label for="Product Type" class="form-label">Mode:</label>
                        <br/>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio"  onChange={(e)=>handleChange(e)} name="mode_domestic" id="inlxineRadio1" value="Domestic"  checked={domesticmode.domestic==true && internationalmode.international==false}/>
                            <label class="form-check-label" for="inlineRadio1">Domestic Mode</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="mode_international" onChange={(e)=>handleChange(e)} id="inlixneRadio2" value="International" checked={internationalmode.international==true && domesticmode.domestic==false }/>
                            <label class="form-check-label" for="inlineRadio2">International Mode</label>
                        </div>
                        </div>
                        <div class="mb-3 mt-3">
                        <label for="Product Type" class="form-label">Parcel Type:</label>
                        <br/>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio"  onChange={(e)=>handleChange(e)} name="document" id="inlxineRadio1" value="Document"  checked={documenttype.document==true && parceltype.parcel==false}/>
                            <label class="form-check-label" for="inlineRadio1">Document</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="parcel" onChange={(e)=>handleChange(e)} id="inlixneRadio2" value="Parcel" checked={parceltype.parcel==true && documenttype.document==false }/>
                            <label class="form-check-label" for="inlineRadio2">Parcel</label>
                        </div>
                        </div>

                        <div class="mb-3 mt-3">
                        <label for="Product Type  Name" class="form-label">Carrier :</label>
                        <select class="form-select" name="carrier_id" onChange={(e)=>handleChange(e)}>
                            <option>Select Carrier</option>
                            {carrierList.map((sub)=>(
                            <option value={sub.carrier_id}>{sub.carrier_name}</option>
                            ))}
                        </select>
                        </div>
                        {producttype.length>0?
                        <div class="mb-3 mt-3">
                        <label for="Product Type  Name" class="form-label">Service :</label>
                        <select class="form-select" name="product_type" onChange={(e)=>handleChange1(e)}>
                            <option>Select Product Type</option>
                            {producttype.map((sub)=>(
                            <option value={sub.product_type_id}>{sub.product_type_name}</option>
                            ))}
                        </select>
                        </div>

                        
:""}                    </div>

                        {/* <div class="mb-3 mt-3">
                        <label for="Product Type" class="form-label">Type:</label>
                        <br/>
                        {carriertype.length>0?
                        carriertype.map((sub)=>(
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio"  />
                            <label class="form-check-label" for="inlineRadio1">{sub.name}</label>
                        </div>
                        )):""}
                       
                        </div> */}
                        {/* <div class="mb-4 mt-3">
                            <label for="" class="form-label">Volumetric weight:</label>
                            <div class="input-group">
                                <span class="input-group-text">HxWxL /</span>
                                <input type="number" class="form-control" placeholder="Volumetric Formula Rate" name="volumetric_weight" onChange={(e)=>handleChange(e)}/>
                            </div>
                            <div id="" class="form-text">Eg: Volumetric weight calculation HeightxWeightxLength / 5000</div>    
                            
                        </div> */}
                        <div class="mb-3 mt-3">
                            <label for="Maximum Weight( KG )" class="form-label">COD Limit</label>
                            <input type="number" class="form-control" id="Maximum Weight( KG )" placeholder="Enter COD limit" name="cod_limit" onChange={(e)=>handleChange(e)} required />
                        </div>
                        {Cod?
                        <div class="mb-3 mt-3">
                            <label for="Maximum Weight( KG )" class="form-label">COD Commission in %</label>
                            <input type="number" class="form-control" id="Maximum Weight( KG )" placeholder="Enter COD limit" name="cod_percentage" onChange={(e)=>handleChange(e)} required />
                       </div>
                       :""}
                       
                       {Cod?
                       <div class="mb-3 mt-3">
                            <label for="Maximum Weight( KG )" class="form-label">COD Commission in Amount</label>
                            <input type="number" class="form-control" id="Maximum Weight( KG )" placeholder="Enter COD limit" name="cod_amount" onChange={(e)=>handleChange(e)} required />
                       </div>
                       :""}
                        <div class="mb-3 mt-3">
                            <label for="Maximum Weight( KG )" class="form-label">Max Weight:</label>
                            <input type="number" class="form-control" id="Maximum Weight( KG )" placeholder="Enter Maximum Weight( KG )" name="max_weight" onChange={(e)=>handleChange(e)} required />
                        </div>
                  </div>
                </div>
            </div>
        </div>

      
        <div class="text-end mt-3">
            <button type="button" class="btn btn-primary btn-lg" onClick={()=>saveNewProduct()}>Save</button>
        </div>
    </section>
    </section>
    </div>
  )
}

export default Add_product

