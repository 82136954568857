import React, { useState, useEffect } from 'react'
// import Shipment_steps from './Shipment_steps'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import exportValue from '../../apiconfig'
import ReactLoading from 'react-loading';

// import exportValue from "../exportValue"
// import Header_nav from './Header_nav';
import axios from 'axios';
import Sub_header from '../Sub_header';
import Left_panel from '../Left_panel';
const Ship_4 = () => {
  
    let navigate = useNavigate();
  
   const location = useLocation()
 console.log("location==>122", location)
  let  parsed = queryString.parse(location.search);
  console.log("parsed",parsed);
  const[dimestate , setdimstate]= useState({height:"" , width:"" , length:""})
  console.log("dimestate === " , dimestate)
  const[deadstate , setdeadstate] = useState({dead_weight:"" , vol_weight:"" , total_weight:""})
  console.log("deadstate ==== " , deadstate)
  const[volume, setVolume] = useState()
  const [error, setError] = useState('');
  const[errorHeight,setHeightError] = useState('');
  const[errorWidth,setWidthError] = useState('');
  const[errorLength,setLengthError] = useState('');
  const[volstate , setVolstate] = useState(null)
  const [errorformat, setErrorformat] = useState('');

  const [orderNumber, setOrderNumber] = useState('');
  const[codlimit , setcodlimit]=useState({limit:0})
  const[parceltype,setparceltypeState] = useState({parcel:false})
  const[documenttype,setdocumenttypeState] = useState({document:false})
  const[state,setState] = useState({  parcel_type:"" , order_date : "" , order_number:"" })
  console.log("state==== " , state)
  const [rowstate, setRowState] = React.useState([{ weight: "", height: "", width: "", length: "", buttonType: "add",error:{} }])
  const [shipstate, setShipstate] = React.useState([{shipment_content:"",total_value:"",quantity:"" ,buttonType: "add", error:{}}])
  console.log("rowstate ==== " , rowstate)
  const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
  const[paymentmode,setpaymentmode]= useState({mode:1})
  console.log("paymentmode",paymentmode)
  const [codvalue,setcodvalue] = useState({cod_amount:0 , err:{}})
  console.log("codvalue",codvalue)
  const [insurancestate, setinsurance] = useState({ insurance: 0 })
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
      const homeSection = document.querySelector('.home-section');
      if (homeSection) {
          homeSection.classList.toggle('sidebar-closed');
      }
  };
  useEffect(() => {
    generateOrderNumber();
    dimension_detail();
    vol_status_function();

    if(parsed.weight !="" && parsed.height!="" && parsed.width!="" && parsed.length!="" && parsed.volumetric_weight!="" ){
      setdimstate({...dimestate , height:parsed.height , width:parsed.width , length:parsed.length})
      setdeadstate({...deadstate , dead_weight:parsed.dead_weight , vol_weight:parsed.volumetric_weight , total_weight:parsed.weight})
    }
  }, []);

  let shipment = []
if(Array.isArray(parsed.items)){
  shipment = parsed.items
  shipment = shipment.map((sub)=>{
    
    console.log("shipment------>111",
    Object.assign({},sub.split(',') ))
    return  Object.assign({},sub.split(',') )
    
   })
  //  console.log("arr",arr)
}else{
  shipment.push(parsed.items)
  shipment = shipment.map((sub)=>{
    
    console.log("shipment------>22",
    Object.assign({},sub.split(',') ))
    return  Object.assign({},sub.split(',') )
    
   })
}
console.log("shipment ======= ?",shipment)


  const dimension_detail = () =>{
    let full_api = exportValue.apiUrl + `/user/get_company_info_api`;
    let sendData = {};
  
    axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
      // console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  }

  const generateOrderNumber = () => {
    // Generate the order number logic
    const randomNumber = Math.floor(Math.random() * 1000000);
    const formattedOrderNumber =  + randomNumber;

    setOrderNumber(formattedOrderNumber);
    setState({...state , order_number:formattedOrderNumber})
  }
//   const handleChange4 = (e) => {
//     console.log("e ", e.target.name);
//     console.log("e checked", e.target.checked);
//     if (e.target.checked == true) {
//       setinsurance({ ...insurancestate, insurance: 1 })
//     }
//     else {
//       setinsurance({ ...insurancestate, insurance: 0 })
//     }
//   }
//   const handleChange=(e)=>{
//     // console.log("namess",e.target.name)
//     // console.log("value",e.target.value)
   
//     let names = e.target.name
//     let value= e.target.value
   
//     if(e.target.type=="text"){
//     setState({...state,[e.target.name]:value})
//     }
//     else if(e.target.type=="radio"){
//         if(e.target.checked==true){
          
//              if(e.target.name=="document"){
//               setparceltypeState({...parceltype,parcel:false})
//               setdocumenttypeState({...documenttype,document:true})
//               setState({...state,parcel_type:"Document"})
//             }
//             else{
//               setparceltypeState({...parceltype,parcel:true})
//               setdocumenttypeState({...documenttype,document:false})
//               setState({...state,parcel_type:"Parcel"})
//             }
//         }
//     }
//     else if(e.target.type=="number"){
//       setState({...state,[e.target.name]:value})
//     }
//     else if(e.target.type=="date"){
//         setState({...state,[e.target.name]:value})
       
//     }
// }
// function addRows(type, index) {
//     console.log("type    ", type, " . ", index)
//     let items = [...rowstate];
//     let ship_items = [...shipstate];
//     console.log("items    ", items)
//     if (type == 1) {
//       let newItem = { weight: "", height: "", width: "", length: "", buttonType: "remove",error:{} };
//       console.log("newItem  =========>   ", newItem)
//       let newShipItem = {shipment_content:"",total_value:"",quantity:"" ,buttonType: "remove", error:{}};

//       items.push(newItem);
//       ship_items.push(newShipItem);
    
//       console.log("items  =========>   ", items)

//       setRowState(items)
//       setShipstate(ship_items)
//     } else if (type == 2) {
//       items.splice(index, 1);
//       ship_items.splice(index,1)
//       setRowState(items)
//       setShipstate(ship_items)
//     }
//   }
//   const handleChange2 = (e, index) => {
//     //  console.log("eee",e.target.value)
//     let items = [...rowstate];
//     let names = e.target.name;
//     let value = e.target.value;
//     if(value > 0) {
//       items[index].error[names] = false;
//      }else{
//       items[index].error[names] = true;
//     }
//    // console.log(items[index][names])
//    items[index][names] = value;
//    setRowState(items)
//     //setState({ ...state, [names]: value })
//   }
//   const handleChange3 = (e, index) => {
//      console.log("eee",e.target.value.length)
//     let items = [...shipstate];
//     let names = e.target.name;
//     let value = e.target.value;
//     if(e.target.type=="number"){
//     if(value > 0 ) {
//       items[index].error[names] = false;
//     }else{
//       items[index].error[names] = true;
//     }
// }
// else{
//     if(value.length > 0 ) {
//         items[index].error[names] = false;
//       }else{
//         items[index].error[names] = true;
//       }
// }
//    // console.log(items[index][names])
//     items[index][names] = value;
//     setShipstate(items)
//     //setState({ ...state, [names]: value })
//   }
//   const payment_mode =(e)=>{
//     if(e.target.name=="prepaid"){
//       setpaymentmode({mode:1})
//     }
//     else{
//       setpaymentmode({mode:2})
//     }
//   }
  
//   const codvalueChange=(e)=>{
      
//     setcodvalue({...codvalue,cod_amount:e.target.value , err:{}})
//   }
  const next_step = () => {
    console.log("order_id")
    if(deadstate.dead_weight!=""){
    if (dimestate.height!="" ) {
       if(dimestate.width!="" ){
       if(dimestate.length!="" ){
        
        let dataToSend = { sender_name: parsed.sender_name,
                            sender_mobile: parsed.sender_mobile,
                            sender_email: parsed.sender_email,
                            sender_address: parsed.sender_address,
                            sender_city: parsed.sender_city,
                            sender_city_id: parsed.sender_city_id,
                            sender_country_name: parsed.sender_country,
                            sender_country_code: parsed.sender_country_code,
                            sender_postal_code: parsed.sender_postal_code,
                            receiver_name: parsed.receiver_name,
                            receiver_mobile: parsed.receiver_mobile,
                            receiver_email: parsed.receiver_email,
                            receiver_address: parsed.receiver_address,
                            receiver_city: parsed.receiver_city,
                            receiver_country_name: parsed.receiver_country,
                            receiver_country_code: parsed.receiver_country_code,
                            receiver_postal_code: parsed.receiver_postal_code,
                            receiver_city_id:parsed.receiver_city_id ,
                            receiver_state_id:parsed.receiver_state_id,
                            sender_state_name:parsed.sender_state_name,
                            receiver_state_name:parsed.receiver_state_name,
                            return_name: parsed.return_name,
                            return_mobile: parsed.return_mobile,
                            return_email: parsed.return_email,
                            return_address: parsed.return_address,
                            return_city: parsed.return_city,
                            return_state_name: parsed.return_state_name,
                            return_country_name: parsed.return_country,
                            return_country_code: parsed.return_country_code,
                            return_postal_code: parsed.return_postal_code,
                            payment_mode:parsed.payment_mode,
                            shipment_type:parsed.shipment_type,
                            cod_amount : parsed.cod_amount,
                            volumetric_weight:volume,
                            total_weight: volume && deadstate.dead_weight!=""?parseFloat(volume)>parseFloat(deadstate.dead_weight)?volume:deadstate.dead_weight:0.00,
                            dead_weight: deadstate.dead_weight, length: dimestate.length, height: dimestate.height,
                            width: dimestate.width, item_name:"",item_value:"",
                            insurance_amount: parsed.insurance,
                            type:parsed.shipment_type,
                            address_id : parsed.sender_address_id,
                            order_id:parsed.order_id,
                            order_date:parsed.order_date,
                            mode:parsed.mode,
                            }
              
                            dataToSend.item_name = shipment.map((sub)=>(
                            sub[1]
                            ))   
                            dataToSend.item_value = shipment.map((sub)=>(

                            sub[0]
                            ))      
                            dataToSend.quantity = shipment.map((sub)=>(

                            sub[2]
                            ))                   
                            
                            dataToSend.customer_id = parsed.customer_id;
                            console.log("datatoSend", dataToSend)

                            let url = exportValue.apiUrl + '/shipment/order_create';

                            // setOtherState({ ...otherState, submit_button: false })
                            axios.post(url, dataToSend, { headers: exportValue.headers })
                            .then((responseJson) => {
                            console.log("responseJson => ", responseJson.data.output);
                            // setOtherState({ ...otherState, submit_button: true })
                            if (responseJson.data.status == true) {
                                navigate({pathname:`/orders`,
                               
                              })   
                              const Toast = Swal.mixin({
                                toast: true,
                                position: 'bottom-end',
                                showConfirmButton: false,
                                timer: 3000
                              })       
                            Toast.fire({
                            background:"rgb(25, 135, 84)",
                            type: 'unsuccess',
                            title: "Order created successfully.",
                            color:"white"
                            });

                            }
                            else{
                                const Toast = Swal.mixin({
                                    toast: true,
                                    position: 'bottom-end',
                                    showConfirmButton: false,
                                    timer: 3000
                                  })       
                                Toast.fire({
                                background:"rgb(25, 135, 84)",
                                type: 'unsuccess',
                                title: "Something Went Wrong.",
                                color:"white"
                                }); 
                            }
                            })
         
     
       
                        }else{
                            setLengthError('Length is Required')
                        }
       }
       else{
        setWidthError('Width is Required');
       }
   
      
 
    }
    else{
        setHeightError('Height is Required'); 
    }
}else{
    setError('Weight is Required'); 
}
}

  const handleInputChange =(e)=>{
    setWidthError('')
    setLengthError('')
    setHeightError('')

     let values = e.target.value 
     setdimstate({...dimestate , [e.target.name]:values})
  }

//   const [weight, setWeight] = useState('0.00');
const handleWeightChange = (e) => {
    setError('')
    
    setdeadstate({...deadstate , [e.target.name]:e.target.value})
    // const inputWeight = event.target.value;
    // const regex = /^(?=.*[1-9])\d*(\.\d{0,3})?$/;
  
    // // Display "0.000" when the input is empty
    // if (inputWeight.trim() === '') {
    //   setdeadstate({ ...deadstate, dead_weight: "0.000" });
    //   setError('');
    //   return;
    // }
  
    // // Split the input value at the decimal point
    // const parts = inputWeight.split('.');
  
    // // Handle the case when the input value contains a decimal point
    // let formattedWeight = parts[0];
    // if (parts.length > 1) {
    //   formattedWeight += '.' + parts[1].padEnd(3, '0');
    // } else {
    //   formattedWeight += '.000';
    // }
  
    // // Display the updated formatted input weight in the input field
    // setdeadstate({ ...deadstate, dead_weight: formattedWeight });
  
    // // Convert the formatted input weight to a fixed decimal format with maximum 3 decimal places
    // const decimalWeight = parseFloat(formattedWeight).toFixed(3);
  
    // if (regex.test(decimalWeight)) {
    //   const numericWeight = parseFloat(decimalWeight);
    //   if (numericWeight >= 0.5) {
    //     setError('');
    //   } else {
    //     setError('Invalid weight. The minimum chargeable weight is 0.50 Kg.');
    //   }
    // } else {
    //   setError('Invalid weight format. Please enter a valid weight with a maximum of 3 digits after the decimal point.');
    // }
  };
  const vol_status_function = () =>{
    let full_api = exportValue.apiUrl + `/user/customer_volumetric_status`;
    let sendData = {};
  
    axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      setVolstate(res.data.volumetric_status);
      console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  }
  
  React.useEffect(() => {
    if ( dimestate.height !== "" && dimestate.width !== "" && dimestate.length !== "") {
        let divisor = volstate === 1 ? 5000 : 1;
        const calculatedVolume = ( dimestate.height * dimestate.width * dimestate.length) / divisor;
        setVolume(calculatedVolume.toFixed(2));
    } else {
        setVolume(0);
    }
}, [ dimestate.height, dimestate.width, dimestate.length, volstate]);
  return (
    <div>
      <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/>
               
     
    <section class="home ">
      
        <section class="mb-3">
          {/* <div class="container"> */}
            <div class="row">
             
              <div class="col-12 col-md">
                <h4 class="pb-3">Package Details</h4>
                <div class="card border-0 order_box">
                  <div class="card-body">
                    <section class="pb-3">
                        <h6 class="">Dead Weight <a href="#" data-bs-toggle="popover"  data-bs-placement="top" data-bs-trigger="hover" data-bs-content="Dead Weight is the physical weight" class="text-muted"><i class="fa fa-question-circle-o" aria-hidden="true"></i></a></h6>
                        <div class="row">
                            <div class="col-12 col-md-4 mb-3">
                                <div class="input-group">
                                  <input  type="text"
  className="form-control"
  placeholder="Your shipment weight"
  name="dead_weight"
  value={deadstate.dead_weight}
  onChange={(e) => handleWeightChange(e)}
   />
                                  <span class="input-group-text">KG</span>
                                </div>
                                {error?<>
                            <small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                               lineHeight: "1.66667",
                               fontWeight: "400",
                               letterSpacing: "0.05em",marginLeft:"4px"}}>{error}</small></>:""}
                                {/* <small class="text-muted">
                                  (Max. 3 digits after decimal place) <br/> Note: The minimum chargeable weight is 0.50 Kg
                                </small> */}
                            </div>
                            {/* {error?<>
                            <small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                               lineHeight: "1.66667",
                               fontWeight: "400",
                               letterSpacing: "0.05em",marginLeft:"4px"}}>{error}</small></>:""}
                            {errorformat?<>
                           <small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                               lineHeight: "1.66667",
                               fontWeight: "400",
                               letterSpacing: "0.05em",marginLeft:"4px"}}>{errorformat}</small></>:""} */}
                        </div>
                    </section>
                    <hr/>
                    <section class="pb-3">
                      <h6 class="">Volumetric Weight <a href="#" data-bs-toggle="popover"  data-bs-placement="top" data-bs-trigger="hover" data-bs-content="Volumetric Weight is calcuated by LxHxW/Range" class="text-muted"><i class="fa fa-question-circle-o" aria-hidden="true"></i></a></h6>
                      <small>Enter packages dimensions to calculate Volumetric Weight</small>
                    
                      <div class="row pt-3">
                          <div class="col-12 col-md-6 mb-3">
                              <div class="row">
                                  <div class="col-12 col-md-4 mb-3">
                                    <label for="" class="form-label">Height:</label>
                                    <div class="input-group">
                                      <input type="number" class="form-control" placeholder="Your shpiment weight" name="height" onChange={(e) => handleInputChange(e)} value={dimestate.height}/>
                                      <span class="input-group-text">CM</span>
                                    </div>
                                    {errorHeight?<>
                            <small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                               lineHeight: "1.66667",
                               fontWeight: "400",
                               letterSpacing: "0.05em",marginLeft:"4px"}}>{errorHeight}</small></>:""}
                                  </div>
                                  <div class="col-12 col-md-4 mb-3">
                                    <label for="" class="form-label">Width:</label>
                                    <div class="input-group">
                                      <input type="number" class="form-control" placeholder="Your shpiment weight" name="width" onChange={(e) => handleInputChange(e)}   value={dimestate.width}/>
                                      <span class="input-group-text">CM</span>
                                    </div>
                                    {errorWidth?<>
                            <small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                               lineHeight: "1.66667",
                               fontWeight: "400",
                               letterSpacing: "0.05em",marginLeft:"4px"}}>{errorWidth}</small></>:""}
                                 
                                  </div>

                                  <div class="col-12 col-md-4 mb-3">
                                    <label for="" class="form-label">Length:</label>
                                    <div class="input-group">
                                      <input type="number" class="form-control" placeholder="Your shpiment weight" name="length" onChange={(e) => handleInputChange(e)}  value={dimestate.length}  />
                                      <span class="input-group-text">CM</span>
                                    </div>
                                    {errorLength?<>
                            <small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                               lineHeight: "1.66667",
                               fontWeight: "400",
                               letterSpacing: "0.05em",marginLeft:"4px"}}>{errorLength}</small></>:""}
                                   
                                  </div>
                              </div>
                          </div>
                          <div class="col-12 col-md-6 mb-3">
                            
                          </div>
                      </div>
                   
                      <div class="card border-0 bg-light">
                        <div class="card-body">
                          <h6>Volumetric Weight <span class="ms-5">{volume} Kg</span></h6>
                        </div>
                      </div>
                    </section>
                    <hr />
                    <section>
                      <div class="card border-0 bg-light py-3">
                        <div class="card-body">
                        <h5 class="mb-3">Applicable Weight   {volume && deadstate.dead_weight !== "" ? 
      (parseFloat(volume) > parseFloat(deadstate.dead_weight) ? volume : deadstate.dead_weight) + " Kg" :
      "0.00 Kg"
    }</h5>
                          <small class="text-muted">*Applicable weight is the heavier among the two weights that is Dead Weight V/s the Volumetric Weight, basis on which freight charges are calculated.<br/>
                            *Final chargeable weight will be based on the weight slab of the courier selected before shipping</small>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div class="py-4 text-end">
                  {/* <a onClick={()=> navigate({pathname:`/customer/create_order_3`,
                                search: `sender_name=${parsed.sender_name}&sender_address=${parsed.sender_address}&sender_country=${parsed.sender_country}&sender_country_code=${parsed.sender_country_code}&sender_postal_code=${parsed.sender_postal_code}&sender_state_name=${parsed.sender_state_name}&sender_city=${parsed.sender_city}&sender_address_id=${parsed.sender_address_id}&sender_city_id=${parsed.sender_city_id}&sender_mobile=${parsed.sender_mobile}&sender_email=${parsed.sender_email}&receiver_name=${parsed.receiver_name}&receiver_email=${parsed.receiver_email}&receiver_mobile=${parsed.receiver_mobile}&receiver_address=${parsed.receiver_address}&receiver_country=${parsed.receiver_country}&receiver_country_code=${parsed.receiver_country_code}&receiver_postal_code=${parsed.receiver_postal_code}&receiver_city=${parsed.receiver_city}&receiver_state_name=${parsed.receiver_state_name}&return_name=${parsed.return_name}&return_email=${parsed.return_email}&return_mobile=${parsed.return_mobile}&return_address=${parsed.return_address}&return_country=${parsed.return_country}&return_country_code=${parsed.return_country_code}&return_postal_code=${parsed.return_postal_code}&return_city=${parsed.return_city}&return_state_name=${parsed.return_state_name}&mode=${parsed.mode}&items=""&shipment_type=${parsed.shipment_type}&insurance=${parsed.insurance}&order_id=${parsed.order_id}&order_date=${parsed.order_date}&cod_amount=${parsed.cod_amount}&payment_mode=${parsed.payment_mode}&weight=${deadstate.weight?deadstate.weight:""}&height=${dimestate.height?dimestate.height:""}&width=${dimestate.width?dimestate.width:""}&length=${dimestate.length?dimestate.length:""}&store_id=${parsed.store_id?parsed.store_id:""}&channel_name=${parsed.channel_name?parsed.channel_name:""}&volumetric_weight=${deadstate.vol_weight?deadstate.vol_weight:""}&dead_weight=${deadstate.dead_weight?deadstate.dead_weight:""}&receiver_state_id=${parsed.receiver_state_id}&receiver_city_id=${parsed.receiver_city_id}`
                              })   } class="btn btn-secondary">Back</a> */}
                  <a onClick={()=>next_step()} class="btn btn-primary">Add Order</a>
              </div>
              </div>
            </div>      
          {/* </div> */}
        </section>
        
    </section>
    
    </section>


    {/* <?php include 'footer.php';?> */}
    </div>
  )
}

export default Ship_4
