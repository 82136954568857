import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import Swal from 'sweetalert2'
import TablePagination from '@mui/material/TablePagination';
import ReactLoading from 'react-loading';
import exportValue from "../../apiconfig";
import Sub_header from '../Sub_header';
import { useNavigate } from "react-router-dom";
import Left_panel from "../Left_panel";

const Pending_kyc = () => {
    let navigate = useNavigate()
    const search1 = window.location.search;
    const params = new URLSearchParams(search1);
    let search = params.get('search')
    let cType = params.get('type')
   // console.log("search ", cType);
    const [state, setState] = useState({ isLoading: true, user_list: [] });
    const [otherStates, setOtherState] = useState({ dstatus: "", aadhar_status:"" , activePage: 1, rowsPerPage: 10, page: 0, total_count: 0, onload: true });
    const [isLoading, setIsLoading] = useState(false);
    const [modalState, setModalState] = useState({ show: false });
    const [newState, setNewState] = useState({ customer_id: "",amount:"",mode:1,cod_wallet_balance:0 });
    const [passwordstate, setpasswordState] = React.useState({customer_id:"" , password:""})
    const [passwordmodal, setpasswordmodal] = React.useState({show:false})

    const [searchfilters, setsearchFilters] = useState({ searchtitle: "" })
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 5000
    })

    useEffect(() => {
        getUsersList(0, true);
    }, [otherStates.dstatus , otherStates.aadhar_status])

    useEffect(() => {
        getUsersList(0, true);
    }, [])

    const getUsersList = (index = 0, onLoad) => {

        setIsLoading(true)
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user_list`;
        let sendData = { find: "", status: otherStates.dstatus, aadhar_status:otherStates.aadhar_status ,  user_type: (cType) ? cType :"", limit: otherStates.rowsPerPage, indexValue: index };

        if (search != null && search.length > 0) {
            sendData.searchvalue = search
        } else if (searchfilters.searchtitle != "") {
            sendData.searchvalue = searchfilters.searchtitle
        }

        
       // console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            setIsLoading(false)
            //console.log("response ", res);
           // console.log("otherStates ", otherStates);
            if (index == 0 && onLoad) {
                setOtherState({ ...otherStates, total_count: res.data.count })

            }
            setState({ ...state, user_list: res.data.output, isLoading: false })



        }).catch((e) => {
            setIsLoading(false)

        });
    }

   
  


const filter = () => {
    setIsLoading(true)
    search = null
    params.delete('search')
    if (searchfilters.searchtitle !== "") {
        params.append('search', searchfilters.searchtitle)
    }

    // setState({ ...state, isLoading: true })
    navigate({ search: params.toString() })
    getUsersList(0,true)
}
const stateHandler = (e) => {
    // state[key] = value
    if (e.target.name == "stitle") {

        params.delete('search')
        search = null;
        setsearchFilters({ ...searchfilters, searchtitle: e.target.value })
        //   navigate({ search: params.toString() })

    }
    //    setsearchFilters({ ...searchfilters, searchtitle:searchfilters.searchtitle})
    setOtherState({ ...otherStates, onload: true })
}
const handlePageChange = (event, newPage) => {
    setOtherState({ ...otherStates, page: newPage })
    //console.log("newPage ", newPage);
    getUsersList(newPage, true, otherStates.rowsPerPage);
}
const handleChangeRowsPerPage = (event) => {
    //console.log("event ", event.target.value);        
    setOtherState({ ...otherStates, rowsPerPage: event.target.value })
    getUsersList(0, true, event.target.value)
}
const statusChange = (status) => {
    console.log("status == " , status)
    setOtherState({ ...otherStates, aadhar_status: status })
}
  return (
    <div>
           <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    <section class="home-section">
            {/* <?php
            include('sub_header.php');
            ?>  */}
            <Sub_header/>

<div class="row py-4">
    <div class="col-12 col-md">
        <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                <li class="breadcrumb-item">
                    <a href="#">
                        <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                    </a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Customers</li>
            </ol>
        </nav>
        <h2 class="h4">KYC</h2>
    </div>
    <div class="col-12 col-md-5">
        
    </div>
    <div class="col-12 col-md text-end">
        <div class="">
            <div class="input-group ">
                <span class="input-group-text">
                    <svg class="icon icon-xs" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" onClick={(e) => filter(e)}>
                        <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                    </svg>
                </span>
                <input type="text" class="form-control" placeholder="Search Customers" name="stitle" value={searchfilters.searchtitle} onChange={(e) => stateHandler(e)} />
            </div>
        </div>
    </div>
</div>
<section>
                        <ul class="nav nav-tabs justify-content-end">
                            <li class="nav-item">
                                <a class={"nav-link" + (otherStates.aadhar_status == "" ? " active" : "")} style={{ cursor: 'pointer' }} onClick={() => statusChange("")}>All</a>
                            </li>
                            <li class="nav-item">
                                <a class={"nav-link" + (otherStates.aadhar_status == 1 ? " active" : "")} style={{ cursor: 'pointer' }} onClick={() => statusChange(1)}>Pending</a>
                            </li>
                            <li class="nav-item">
                                <a class={"nav-link" + (otherStates.aadhar_status == 2 ? " active" : "")} style={{ cursor: 'pointer' }} onClick={() => statusChange(2)}>Success</a>
                            </li>
                           
                        </ul>
                    </section>

        <div class="card card-body border-0 shadow table-wrapper table-responsive">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th class="border-gray-200">#</th>
                        <th class="border-gray-200">Account#</th>	
                        <th class="border-gray-200">Company Name</th>
                        <th class="border-gray-200">Phone#</th>
                        <th class="border-gray-200">Email</th>	
                        <th class="border-gray-200">Status</th>
                        <th class="border-gray-200">Action</th>
                    </tr>
                </thead>
                <tbody>
                {state.user_list.map((user, index) =>
                    <tr>
                        <td>
                            {index+1}
                        </td>
                        <td>
                            <span class="fw-normal"><a href="">{user.customer_id}</a></span>
                        </td>                        
                        <td>
                        <div class="d-flex">
                            <div class="">
                            {/* <img src="https://dummyimage.com/50/1976d2/ffffff&text=NH" class="img-fluid rounded-circle" /> */}
                            </div>
                            <div class="p-1">
                            <a href={"/customers_info/"+user.customer_id} class="fw-bold text-info fw-700">
                                                            {user.full_name}
                                                        </a><br/>
                                                        <small class="text-muted">{user.address?user.address:""},{user.city?user.city:""}</small>
                            </div>
                        </div>
                            
                        </td>
                        <td>{user.mobile}</td>
                                            <td>{user.email}</td>
                        <td>
                            {user.aadhar_kyc==true?  <span class="badge rounded-pill bg-success">Success</span>:
                            <span class="badge rounded-pill bg-warning">Pending</span>}
                            </td>
                        <td><a href={`/kyc_detail/${user.customer_id}`} class="btn btn-primary btn-sm">View Details</a></td>
                    </tr>   
                )}                   
                </tbody>
            </table>
            <TablePagination
                                component="div"
                                rowsPerPageOptions={[5, 10]}
                                count={otherStates.total_count}
                                page={otherStates.page}
                                onPageChange={handlePageChange}
                                rowsPerPage={otherStates.rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}

                            />
        </div> 
                

</section>

    </div>
  )
}

export default Pending_kyc
