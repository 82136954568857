import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from 'sweetalert2'
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import { useParams } from 'react-router-dom'
import Sub_header from '../Sub_header';
import exportValue from "../../apiconfig";
import Left_panel from "../Left_panel";

const Customers_info = () => {
    const {customer_id} = useParams();
   

    const [state, setState] = useState({ isLoading: true, user_info: {} });
    console.log("state == " , state)
    const [limitstate,setlimit] = useState({credit_limit:0})
    const [isLoading, setIsLoading] = useState(false);
    const [newState, setNewState] = useState({ customer_id: "",amount:"",mode:1,cod_wallet_balance:0 });
    const [modalState, setModalState] = useState({ show: false });
    const [passwordstate, setpasswordState] = React.useState({customer_id:"" , password:""})
    const [passwordmodal, setpasswordmodal] = React.useState({show:false})
    const [creditState, setCreditState] = useState({ customer_id: "",amount:0,order_id:"" });
    const [modalcredit, setModalCreditState] = useState({ show: false });
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    const homeSection = document.querySelector('.home-section');
    if (homeSection) {
        homeSection.classList.toggle('sidebar-closed');
    }
};
    useEffect(() => {
        getUsersInfo();
    }, [])

    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 5000
    })

    const getUsersInfo = () => {

        setIsLoading(true)
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user_detail`;
        let sendData = { customer_id:customer_id };

       
       // console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            setIsLoading(false) 
            if(res.data.output.length ==1) {       
            setState({ ...state, user_info: res.data.output[0], isLoading: false })
            }



        }).catch((e) => {
            setIsLoading(false)

        });
    }

    const updateUsersInfo = () => {

        setIsLoading(true)
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user_update`;
        let sendData = state.user_info;

       
       // console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            
                 console.log("res", res);
                 if(res.data.status == 200) {
                    Toast.fire({
                        background: "#10b93b",
                        type: 'success',
                        title: "Updated Successfully !",
                        color: "white"
                    });
                 }



        }).catch((e) => {
            setIsLoading(false)

        });
    }

    const inputEditHandleChange = (e) => {
         console.log("e", e);
        // console.log("type", e.target.type);
        // console.log("name", e.target.name);
        // console.log("value", e.target.value);
        // console.log("file", e.target.files);

       let userTemp = {...state.user_info};
      // console.log("userTemp ", userTemp[e.target.name]);
       userTemp[e.target.name] =  e.target.value;
        setState({ ...state, user_info:userTemp });
        
    }

    const handlecreditlimit =(e)=>{
     setlimit({credit_limit:e.target.value})
     let userTemp = {...state.user_info};
      // console.log("userTemp ", userTemp[e.target.name]);
       userTemp[e.target.name] =  e.target.value;
        setState({ ...state, user_info:userTemp });

    }

    const setCreditlimit=()=>{
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user_credit_limit_update`;
        let sendData = {customer_id:customer_id , credit_limit:limitstate.credit_limit};
        if(limitstate.credit_limit!=0){
       axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            
                 console.log("res", res);
                 if(res.data.status == 200) {
                    Toast.fire({
                        background: "#10b93b",
                        type: 'success',
                        title: "Updated Successfully !",
                        color: "white"
                    });
                 }
   }).catch((e) => {
           
    Toast.fire({
        background: "#10b93b",
        type: 'success',
        title: "Something Went Wrong",
        color: "white"
    });
        });
    }else{
        Toast.fire({
            background: "#10b93b",
            type: 'success',
            title: "Please Fill credit limit value greater than zero",
            color: "white"
        });
    }
    }
    const addMoneyWallet = (user) => {
        console.log(user);
        setNewState({ ...newState, customer_id: user.customer_id,amount:0,cod_wallet_balance:user.cod_wallet_balance,mode:1 });
        setModalState({show: true})

    }
    const submitAddWalet = () =>{
        console.log("newState ",newState);
        if(newState.amount != ""){
            let flag = true;
            if(newState.mode == 2 && newState.amount > newState.cod_wallet_balance ){
                flag = false;
                Toast.fire({
                    background: "#e63900",
                    type: 'error',
                    title: "Insuficient balance",
                    color: "white"
                });
            }
            if(flag){
            setIsLoading(true)
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user_wallet_update`;
        let sendData= newState;
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            Toast.fire({
                background: "#e63900",
                type: 'success',
                title: "Wallet Balance Added",
                color: "white"
            });
            setIsLoading(false)
            setModalState({show: false})
            // getUsersList(0, true);

        }).catch((e) => {
            setIsLoading(false)

        });
    }

        }else {
            Toast.fire({
                background: "#e63900",
                type: 'error',
                title: "Please add amount",
                color: "white"
            });
        }
    }

    const inputHandleChange = (e) => {
        console.log("e", e);
       // console.log("type", e.target.type);
       // console.log("name", e.target.name);
       // console.log("value", e.target.value);
       // console.log("file", e.target.files);
       if (e.target.type == "file") {
           setNewState({ ...newState, [e.target.name]: e.target.files });
       } else {
           setNewState({ ...newState, [e.target.name]: e.target.value });
       }
   }
   const openpasswordModal=(user)=>{
    setpasswordState({...passwordstate,customer_id:user.customer_id})
    setpasswordmodal({show:true})
  }
  const closeModalpassword=()=>{
      setpasswordState({...passwordstate,customer_id:"", password:""})
    setpasswordmodal({show:false})
  }
  
  const passwordchange=(e)=>{
      setpasswordState({...passwordstate,password:e.target.value})
  
  }
  
  const submitPassword=()=>{
      
  if(passwordstate.password!=""){
  let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/customer_temp_password_update`;
  let sendData= {customer_id:passwordstate.customer_id , password:passwordstate.password}
  console.log("send_data === " , sendData);
  axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
      if(res.data.status==true){
          setpasswordmodal({show:false})
          setpasswordState({...passwordstate, customer_id:"" ,password:""})
          
          Toast.fire({
              background: "#e63900",
              type: 'error',
              title: "Password Created and Valid for Next 30 minutes",
              color: "white"
          });
      }
     
  }).catch((e) => {
      // setIsLoading(false)
      Toast.fire({
          background: "#e63900",
          type: 'error',
          title: "Something Went Wrong",
          color: "white"
      });
  
  });
  
  
  }else {
      Toast.fire({
          background: "#e63900",
          type: 'error',
          title: "Please Fill Password",
          color: "white"
      });
  }
  }
  
  const skip_kyc = (user)=>{
     
          let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/customer_skip_kyc`;
          let sendData= {customer_id:user.customer_id}
          console.log("send_data === " , sendData);
          axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
              if(res.data.status==true){
                  Toast.fire({
                      background: "#e63900",
                      type: 'error',
                      title: "KYC Skipped for this Customer !",
                      color: "white"
                  });
              }
              else{
                  Toast.fire({
                      background: "#e63900",
                      type: 'error',
                      title: "Something Went Wrong !",
                      color: "white"
                  });
              }
             
          }).catch((e) => {
              // setIsLoading(false)
              Toast.fire({
                  background: "#e63900",
                  type: 'error',
                  title: "Something Went Wrong",
                  color: "white"
              });
          
          });
  }
  const addCreditnote = (user) => {
    console.log(user);
    setCreditState({ ...creditState, customer_id: user.customer_id,amount:0 });
    setModalCreditState({show: true})

}
const inputHandleChangecredit = (e) => {
    console.log("e", e);
      setCreditState({ ...creditState, [e.target.name]: e.target.value });
   
}
const submitCreditNote = () =>{
    console.log("creditState ",creditState);
    if(creditState.amount > 0){
      if(creditState.order_id!=""){
      let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user_credit_wallet_update`;
    let sendData= creditState;
    axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
        if(res.data.status==true){
        setModalCreditState({show: false})
        // getUsersList(0, true);
        getUsersInfo();
        Toast.fire({
            background: "#e63900",
            type: 'success',
            title: res.data.message,
            color: "white"
        });
        
    }
        else{
            Toast.fire({
                background: "#e63900",
                type: 'error',
                title: res.data.message,
                color: "white"
            });
        }

    }).catch((e) => {
        // setIsLoading(false)

    });
      }else{
        Toast.fire({
            background: "#e63900",
            type: 'error',
            title: "Please FilL Order ID",
            color: "white"
        });
      }

    }else {
        Toast.fire({
            background: "#e63900",
            type: 'error',
            title: "Please add amount",
            color: "white"
        });
    }
}
  return (
    // <div>
    //   <Sub_header/>
    //   <div style={{marginLeft:"15px",marginRight:"15px"}}>
    //   <section>
    //         <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
    //             <div class="d-block mb-4 mb-md-0">
    //                 <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
    //                     <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
    //                         <li class="breadcrumb-item">
    //                             <a href="#">
    //                                 <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
    //                             </a>
    //                         </li>
    //                         <li class="breadcrumb-item active" aria-current="page">Customers</li>
    //                     </ol>
    //                 </nav>
    //                 <h2 class="h4">{}</h2>
                    
    //             </div>
              
    //             <div class="btn-toolbar mb-2 mb-md-0">
    //                 <span class="badge rounded-pill bg-success">Active</span>                  
    //             </div>
               
    //         </div>
            
    // </section>


    // <section class="mb-3">
    //     <div class="row">
    //       <div class="col-12 col-md">
    //       <section>
    //     <ul class="nav nav-tabs justify-content-end">
    //         <li class="nav-item">
    //           <a class="nav-link active" href="#">Info</a>
    //         </li>
    //         <li class="nav-item">
    //           <a class="nav-link" href={`/shipment_report?customer_id=${encodeURIComponent(customer_id)}`}>Shipments</a>
    //         </li>
           
    //     </ul>
    // </section>
    // <section id="generalinfo">
    //     <div class="row">
    //         <div class="col-12 col-xl-12">
    //             <div class="card card-body border-0 shadow mb-4">
    //                 <h2 class="h5 my-4">Customer Information</h2>
    //                 <form>

    //                     <div class="row">
    //                         <div class="col-md-12 mb-3">
    //                             <div>
    //                                 <label for="first_name">Full Name</label>
    //                                 <input class="form-control" id="first_name" type="text" placeholder="name" required value={state.user_info.full_name} onChange={(e)=>inputEditHandleChange(e)} name="full_name"/>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <div class="row">
    //                         <div class="col-md-6 mb-3">
    //                             <div class="form-group">
    //                                 <label for="email">Email</label>
    //                                 <input class="form-control" id="email" type="email" placeholder="name@company.com" required value={state.user_info.email} onChange={(e)=>inputEditHandleChange(e)} name="email"/>
    //                             </div>
    //                         </div>
    //                         <div class="col-md-6 mb-3">
    //                             <div class="form-group">
    //                                 <label for="phone">Phone</label>
    //                                 <input class="form-control" id="phone" type="text" placeholder="Enter phone" required value={state.user_info.mobile} onChange={(e)=>inputEditHandleChange(e)} name="mobile"/>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <h2 class="h5 my-4">Location</h2>
    //                     <div class="row">
    //                         <div class="col-sm-12 mb-3">
    //                             <div class="form-group">
    //                                 <label for="address">Address</label>
    //                                 <input class="form-control" id="address" type="text" placeholder="Enter your home address" required value={state.user_info.address} onChange={(e)=>inputEditHandleChange(e)} name="address"/>
    //                             </div>
    //                         </div>
    //                         <div class="col-sm-4 mb-3">
    //                             <label for="state">Country</label>
    //                             <select class="form-select w-100 mb-0" id="state" name="state" aria-label="State select example">
    //                                 <option selected>Country</option>
    //                             </select>
    //                         </div>
    //                         <div class="col-sm-4 mb-3">
    //                             <label for="state">State</label>
    //                             <select class="form-select w-100 mb-0" id="state" name="state" aria-label="State select example">
    //                                 <option selected>State</option>
    //                             </select>
    //                         </div>
    //                         <div class="col-sm-4 mb-3">
    //                             <label for="state">City</label>
    //                             <select class="form-select w-100 mb-0" id="state" name="state" aria-label="State select example">
    //                                 <option selected>City</option>
    //                             </select>
    //                         </div>
    //                     </div>

                   

    //                     <h2 class="h5 my-4">Update Password</h2>
    //                     <div class="row">
    //                         <div class="col-12 col-md-6">
    //                             <label for="">Update Password</label>
    //                             <input type="text" class="form-control" id="text" placeholder="enter new password" name="password" onChange={(e)=>inputEditHandleChange(e)}/>
    //                         </div>
    //                     </div>

    //                     <div class="mt-3">
    //                         <button class="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={()=>updateUsersInfo()}>Save</button>
    //                     </div>
    //                 </form>
    //             </div>
    //         </div>
    //     </div>
    // </section>
    //       </div>
    //       <div class="col-12 col-md-4">
    //         <section>
    //             <div class="card">
    //               <div class="card-body">
    //                 <div class="row">
    //                     <div class="col-md-12 mb-3">
    //                     <p> Wallet Balance: {(state.user_info.wallet_balance) ? state.user_info.wallet_balance.toFixed(2):0}</p>
                           
    //                     </div>

    //                     <div class="col-12">
    //                         <div class="mb-3">
    //                             <label for="first_name">Credit Limit:</label>
    //                             <div class="input-group">
    //                                 <span class="input-group-text">INR</span>
    //                                 <input type="text" class="form-control" placeholder="Enter credit amount" name="credit_limit" onChange={(e)=>handlecreditlimit(e)} value={state.user_info.credit_limit}/>
    //                               </div>
    //                         </div>
    //                         <div  class="mb-3">
    //                         <button class="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={()=>setCreditlimit()}>Save</button>
    //                         </div>
                         
    //                     </div>
    //                 </div>
    //               </div>
    //             </div>
    //         </section>
    //       </div>
    //     </div> 
    // </section>
    
    //   </div>
    // </div>
    <div>
        <Modal show={modalcredit.show} onHide={() => setModalCreditState({ show: false })}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Money</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <label for="email">Amount</label>
                            <input className="form-control" id="first_name" type="number" placeholder="" name="amount" onChange={(e) => inputHandleChangecredit(e)} required />
                            {/* <span>*Please fill </span> */}
                        </div>
                         <div className="col-md-12 mb-3">
                            <label for="email">Order ID</label><br/>
                            <input  id="first_name" type="text" className="form-control" name="order_id" onChange={(e) => inputHandleChangecredit(e)}  value={creditState.order_id}/><br/>
                           
                          </div>
                      </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalCreditState({ show: false })}>
                        Cancel
                    </Button>
                    <Button variant="primary"
                        onClick={() =>
                            submitCreditNote()
                        }>
                        Pay
                    </Button>
                </Modal.Footer>
            </Modal>
                     <Modal show={modalState.show} onHide={() => setModalState({ show: false })}>
                 <Modal.Header closeButton>
                     <Modal.Title>Add Money</Modal.Title>
                 </Modal.Header>
                 <Modal.Body>
                     <div className="row">
                         <div className="col-md-12 mb-3">
                             <label for="email">Amount</label>
                             <input className="form-control" id="first_name" type="number" placeholder="" name="amount" onChange={(e) => inputHandleChange(e)} required />
                             <span>*Please fill </span>
                         </div>
                          <div className="col-md-12 mb-3">
                             <label for="email">Payment Mode</label><br/>
                             <input  id="first_name" type="radio" name="mode" onChange={(e) => inputHandleChange(e)} checked={newState.mode == 1} value={1}/> Self<br/>
                             <input  id="first_name" type="radio" name="mode" onChange={(e) => inputHandleChange(e)} checked={newState.mode == 2} value={2}/> COD
                            

                         </div>
                         {(newState.mode == 2)?
                         <div className="col-md-12 mb-3" >
                      
                            <label for="email">COD Wallet Balance: </label>
                            { " "+newState.cod_wallet_balance}
                         
                        </div>  :""
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalState({ show: false })}>
                        Cancel
                    </Button>
                    <Button variant="primary"
                        onClick={() =>
                            submitAddWalet()
                        }>
                        Pay
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={passwordmodal.show} onHide={() => closeModalpassword()}>
                <Modal.Header closeButton>
                     <Modal.Title>Create Temp Password</Modal.Title>
                 </Modal.Header>
                 <Modal.Body>
                     <div className="row">
                         <div className="col-md-12 mb-3">
                             <label for="email">Password</label>
                             <input className="form-control" id="first_name" type="text" placeholder="" name="password" value={passwordstate.password} onChange={(e) => passwordchange(e)} required />
                             {/* <span>*Please fill </span> */}
                         </div>
                     </div>
                 </Modal.Body>
                 <Modal.Footer>
                     <Button variant="secondary" onClick={() => closeModalpassword()}>
                         Cancel
                     </Button>

                     <Button variant="primary"
                         onClick={() =>
                             submitPassword()
                         }>
                         Create Password
                     </Button>
                 </Modal.Footer>
             </Modal>
        {/* <?php
            include('header.php');
        ?>

        <?php
            include('left_nav.php');
        ?> */}
       <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    <section class="home-section">
            {/* <?php
            include('sub_header.php');
            ?> 
         */}
<Sub_header/>
                    <div class="row my-4">
                <div class="col-12 col-md">
                    <div class="d-flex">
                        <div class="back_btn_wrap">
                            <a href="" class="back_btn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="corner-up-left" class="lucide lucide-corner-up-left"><polyline points="9 14 4 9 9 4"></polyline><path d="M20 20v-7a4 4 0 0 0-4-4H4"></path></svg>
                            </a>
                        </div>
                        <div class="">
                            <h2>Customer Information</h2>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-2 text-end">
                    
                </div>
                <div class="col-12 col-md-2 text-end basic_status">
                {(state.user_info.status == 1) ?
                                                  <span class="status active"> <i class="fa fa-check" aria-hidden="true"></i> Active</span> :
                                                    (state.user_info.status == 2) ?
                                                    <span class="status active"> <i class="fa fa-check" aria-hidden="true"></i> Inactive</span> :
                                                        (state.user_info.status == 3) ?
                                                        <span class="status active"> <i class="fa fa-check" aria-hidden="true"></i> Suspended</span> :
                                                            (state.user_info.status == 4) ?
                                                            <span class="status active"> <i class="fa fa-check" aria-hidden="true"></i> Deleted</span> : ""
                                                }
                  
                </div>
            </div>
            
             <div class="row mt-3 cust_info_box">
                    <div class="col-12 col-md-3">
                        <div class="card border-0 mb-3">
                        <div class="card-body">
                            <div class="cust_profile_head">
                                <figure>
                                    <img src="../../assets/img/profile.png" class="img-fluid" />
                                </figure>
                                <h2>{state.user_info.full_name} <small  data-bs-toggle="tooltip" title="Verified"><i class="fa fa-check-circle text-primary" aria-hidden="true"></i></small></h2>
                                <small>{state.user_info.company_name?state.user_info.company_name:""}</small>
                            </div>
                            <div class="cust_profile_list_i">
                                <ul class="d-flex justify-content-center">
                                    <li>
                                        <a href={`mailto:${state.user_info.email}`} data-bs-toggle="tooltip" title={`${state.user_info.email}`}>
                                            <span><i class="fa fa-envelope-o" aria-hidden="true"></i></span>
                                            <h6>Email</h6>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`call:${state.user_info.mobile}`} data-bs-toggle="tooltip" title={`${state.user_info.mobile}`}>
                                            <span><i class="fa fa-phone" aria-hidden="true"></i></span>
                                            <h6>Call</h6>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="mb-3 text-center">
                           
                                <small>Account current status is  {(state.user_info.status == 1) ?
                                                  <strong class="text-success">Active</strong> :
                                                    (state.user_info.status == 2) ?
                                                    <strong class="text-success">Inactive</strong> :
                                                        (state.user_info.status == 3) ?
                                                        <strong class="text-success">Suspended</strong> :
                                                            (state.user_info.status == 4) ?
                                                            <strong class="text-success">Deleted</strong> : ""
                                                }</small>
                            </div>
                            <div class="mb-1">
                                <div class="d-flex">
                                    <div class="p-3">
                                        <i class="bx bx-wallet"></i>
                                    </div>
                                    <div class="ps-2 ">
                                        <small class="text-muted">Wallet Balance</small>
                                        <p><a href="">₹{state.user_info.wallet_balance?state.user_info.wallet_balance.toFixed(2):0}</a></p>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-1">
                                <div class="d-flex">
                                    <div class="p-3">
                                        <i class="bx bx-wallet"></i>
                                    </div>
                                    <div class="ps-2 ">
                                        <small class="text-muted">COD Wallet Balance</small>
                                        <p><a href="">₹{state.user_info.cod_wallet_balance?state.user_info.cod_wallet_balance.toFixed(2):0}</a></p>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-1">
                                <div class="d-flex">
                                    <div class="p-3">
                                        <i class="bx bx-money"></i>
                                    </div>
                                    <div class="ps-2 ">
                                        <small class="text-muted">Available Credit Limit</small>
                                        <p><a href="">₹{state.user_info.wallet_balance < 0 && state.user_info.credit_limit
    ? Number(state.user_info.wallet_balance) + Number(state.user_info.credit_limit)
    : state.user_info.credit_limit || 0}</a></p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-grid">
                              <a onClick={()=>addMoneyWallet(state.user_info)} class="btn btn-primary btn-block">Add Money to Wallet</a>
                            </div>
                            <hr/>
                            <div class="cust_profile_left_info">


                                <h5>Company Name</h5>
                                <h6>{state.user_info.company_name?state.user_info.company_name:""}</h6>

                                <h5>Full Name</h5>
                                <h6>{state.user_info.full_name}</h6>
                                
                                <h5>Email</h5>
                                <h6>{state.user_info.email}</h6>

                                <h5>Phone</h5>
                                <h6>{state.user_info.mobile}</h6>


                                <h5>Address</h5>
                                <h6>{state.user_info.address?state.user_info.address:""}</h6>

                                <h5>Country</h5>
                                <h6>{state.user_info.country?state.user_info.country:""}</h6>


                                <h5>State</h5>
                                <h6>{state.user_info.state?state.user_info.state:""}</h6>

                                <h5>City</h5>
                                <h6>{state.user_info.city?state.user_info.city:""}</h6>


                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-12 col-md">

                        <ul class="nav nav-tabs mb-1">
                            <li class="nav-item">
                              <a class="nav-link active" data-bs-toggle="tab" href="#panel_1">Customer Information</a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" data-bs-toggle="tab" href="#panel_2">KYC Settings</a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" data-bs-toggle="tab" href="#panel_3">Other Settings</a>
                            </li>
                          </ul>
                          
                          {/* <!-- Tab panes --> */}
                          <div class="tab-content">
                            <div class="tab-pane  active" id="panel_1">
                                <section id="generalinfo">
                                    <div class="row">
                                        <div class="col-12 col-xl-12">
                                            <div class="card card-body border-0  mb-4">
                                                <h2 class="h5 my-4">Customer Information</h2>
                                                <form>
                            
                                                    <div class="row">
                                                        <div class="col-md-12 mb-3">
                                                            <div>
                                                                <label for="first_name">Full Name</label>
                                                                <input class="form-control" id="first_name" type="text" placeholder="name" required value={state.user_info.full_name} onChange={(e)=>inputEditHandleChange(e)} name="full_name"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-6 mb-3">
                                                            <div class="form-group">
                                                                <label for="email">Email</label>
                                                                <input class="form-control" id="email" type="email" placeholder="name@company.com" required value={state.user_info.email} onChange={(e)=>inputEditHandleChange(e)} name="email"/>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 mb-3">
                                                            <div class="form-group">
                                                                <label for="phone">Phone</label>
                                                                <input class="form-control" id="phone" type="text" placeholder="Enter phone" required value={state.user_info.mobile} onChange={(e)=>inputEditHandleChange(e)} name="mobile"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h2 class="h5 my-4">Location</h2>
                                                    <div class="row">
                                                        <div class="col-sm-12 mb-3">
                                                            <div class="form-group">
                                                                <label for="address">Address</label>
                                                                <input class="form-control" id="address" type="text" placeholder="Enter your home address" required value={state.user_info.address} onChange={(e)=>inputEditHandleChange(e)} name="address"/>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4 mb-3">
                                                            <label for="state">Country</label>
                                                            <select class="form-select w-100 mb-0" id="state" name="state" aria-label="State select example">
                                                                <option selected="">Country</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-sm-4 mb-3">
                                                            <label for="state">State</label>
                                                            <select class="form-select w-100 mb-0" id="state" name="state" aria-label="State select example">
                                                                <option selected="">State</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-sm-4 mb-3">
                                                            <label for="state">City</label>
                                                            <select class="form-select w-100 mb-0" id="state" name="state" aria-label="State select example">
                                                                <option selected="">City</option>
                                                            </select>
                                                        </div>
                                                    </div>
                            
                                                    <h2 class="h5 my-4">Update Password</h2>
                                                    <div class="row">
                                                        <div class="col-12 col-md-6">
                                                            <label for="">Update Password</label>
                                                            <input type="text" class="form-control" id="text" placeholder="enter new password" name="password" onChange={(e)=>inputEditHandleChange(e)}/>
                                                        </div>
                                                    </div>
                            
                                                    <div class="mt-3">
                                                    <button class="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={()=>updateUsersInfo()}>Save</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div class="tab-pane  fade" id="panel_2">
                                <section id="generalinfo">
                                    <div class="row">
                                        <div class="col-12 col-xl-12">
                                            <div class="card card-body border-0  mb-4">
                                                <form>
                                                    <h2 class="h5 my-4">KYC Inforamtion</h2>
                                                    <div class="row ">
                                                        {/* <div class="col-12 col-md">
                                                            <label class="form-check-label" for="inlineRadio2">Verified</label>
                                                        </div> */}
                                                        <div class="col-12 col-md-4">
                                                            {/* <div class="form-check form-switch  ">
                                                               <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked/>
                                                               <label class="form-check-label" for="flexSwitchCheckChecked">Verified</label>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col-12 col-md">
                                                            <label class="form-check-label" for="inlineRadio2">Aadhar Number</label>
                                                            <input type="text" class="form-control" id="text" placeholder="Enter details name" value={state.user_info.aadhaar_number?state.user_info.aadhaar_number:""} disabled/>
                                                        </div>
                                                        <div class="col-12 col-md-4">
                                                            {/* <div class="form-check form-switch  pt-4">
                                                               <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked/>
                                                               <label class="form-check-label" for="flexSwitchCheckChecked">Verified</label>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-12 col-md">
                                                            <label class="form-check-label" for="inlineRadio2">GST Number</label>
                                                            <input type="text" class="form-control" id="text" placeholder="Enter details name" value={state.user_info.gst_number?state.user_info.gst_number:""} disabled/>
                                                        </div>
                                                        <div class="col-12 col-md-4">
                                                            {/* <div class="form-check form-switch  pt-4">
                                                               <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked/>
                                                               <label class="form-check-label" for="flexSwitchCheckChecked">Verified</label>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    
                            
                            
                                                    {/* <div class="mt-3">
                                                        <button class="btn btn-gray-800 mt-2 animate-up-2" type="submit">Save</button>
                                                    </div> */}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div class="tab-pane  fade" id="panel_3">
                                <section id="generalinfo">
                                    <div class="row">
                                        <div class="col-12 col-xl-12">
                                            <div class="card card-body border-0  mb-4">
                                                <form>
                                                    <h2 class="h5 my-4">Other Settings</h2>
                                                    <div class="row ">
                                                        <div class="col-12 col-md">
                                                            <div class="d-flex justify-content-between">
                                                                <div class="">
                                                                    <label for="email" class="form-label">Credit Limit:</label>
                                                                </div>
                                                                {/* <div class="ps-2 ">
                                                                    <label for="email" class="form-label text-primary">Current Credit Limit: INR 20,000</label>
                                                                </div> */}
                                                            </div>
                                                            <div class="input-group">
                                                                <span class="input-group-text">INR</span>
                                                                <input type="text" class="form-control" placeholder="Enter credit amount" name="credit_limit" onChange={(e)=>handlecreditlimit(e)} value={state.user_info.credit_limit}/>
                                                              </div>
                                                        </div>
                                                    </div>
                                                    
                            
                            
                                                    <div class="mt-3">
                                                        <button class="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={()=>setCreditlimit()}>Save</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                          </div>

                        

                        
                    </div>
                    <div class="col-12 col-md-3">
                        <div class="card border-0 mb-3">
                            <div class="card-body">
                                <div class="d-grid mb-3">
                                    <a  class="btn btn-light-o btn-block" onClick={()=>addMoneyWallet(state.user_info)}>Add Money to Wallet</a>
                                </div>
                                {/* <div class="d-grid mb-3">
                                    <a href="" class="btn btn-light-o btn-block">Add Money from COD W to Wallet</a>
                                </div> */}
                                <div class="d-grid mb-3">
                                    <a href={`/products/${state.user_info.customer_id}`} class="btn btn-light-o btn-block">Set Custom Pricing</a>
                                </div>
                                <div class="d-grid mb-3">
                                    <a href={`/master_new_customer/${state.user_info.customer_id}`} class="btn btn-light-o btn-block">Set Dedicated Carrier Code</a>
                                </div>
                                <div class="d-grid mb-3">
                                    <a onClick={(e)=>openpasswordModal(state.user_info)} class="btn btn-light-o btn-block">Create Temp Password access</a>
                                </div>
                                <div class="d-grid mb-3">
                                    <a onClick={(e)=>skip_kyc(state.user_info)} class="btn btn-light-o btn-block">Skip Kyc</a>
                                </div>
                                <div class="d-grid mb-3">
                                    <a onClick={()=>addCreditnote(state.user_info)} class="btn btn-light-o btn-block">Issue Credit Note</a>
                                </div>
                                {/* <div class="d-grid mb-3">
                                    <a href="" class="btn btn-light-o btn-block">Issue Debit Note</a>
                                </div> */}
                            </div>
                        </div>
                        {/* <div class="card border-0 mb-3 cust_profile_rg_stat_bx">
                          <div class="card-body">
                                <div class="card mb-3">
                                <div class="card-body">
                                    <div class="row d-block d-xl-flex align-items-center">
                                        <div class="col-6 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                            <div class="icon-shape icon-shape-primary-o rounded me-4 me-sm-0">
                                                <i class="fa fa-cube fa-2x" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div class="col-6 col-xl-7 px-xl-0">
                                            <div class="d-none d-sm-block">
                                                <h2 class="h6 text-gray-400 mb-0">Shipments</h2>
                                                <h3 class="mb-2 text-muted">
                                                    <a href="">45k</a>
                                                </h3>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div class="card mb-3">
                                    <div class="card-body">
                                    <div class="row d-block d-xl-flex align-items-center">
                                        <div class="col-6 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                            <div class="icon-shape icon-shape-primary-o rounded me-4 me-sm-0">
                                                <i class="fa fa-first-order fa-2x" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div class="col-6 col-xl-7 px-xl-0">
                                            <div class="d-none d-sm-block">
                                                <h2 class="h6 text-gray-400 mb-0">Orders</h2>
                                                <h3 class=" mb-2">
                                                    <a href="">45k</a>
                                                </h3>
                                            </div> 
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="card mb-3">
                                    <div class="card-body">
                                    <div class="row d-block d-xl-flex align-items-center">
                                        <div class="col-6 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                            <div class="icon-shape icon-shape-primary-o rounded me-4 me-sm-0">
                                                <i class="fa fa-usd fa-2x" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div class="col-6 col-xl-7 px-xl-0">
                                            <div class="d-none d-sm-block">
                                                <h2 class="h6 text-gray-400 mb-0">Recharges</h2>
                                                <h3 class="mb-2">
                                                    <a href="">$209394</a>
                                                </h3>
                                            </div> 
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
             </div>
                    

    </section>


{/* <?php
include('footer.php');
?>   */}
    </div>
  )
}

export default Customers_info
