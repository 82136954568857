import React, { useState, useEffect } from "react";
import Sub_header from './Sub_header'

import axios from "axios";


import exportValue from "../apiconfig";
import ReactLoading from 'react-loading';
import Swal from 'sweetalert2'
const Customer_kyc_settings = () => {
    const [state, setstate] = useState({});
    const [aadharstate , setaadharstate] = useState([])
    const [gststate , setgststate] = useState([])

    
    React.useEffect(()=>{
        getCompanyDetail()
        getkyc_settings()
    },[])
    const getCompanyDetail = () => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/company_info_api`;
        let sendData = {};
        // console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
              console.log("companyData ", res);
            if (res.data.companyDetail.length > 0) {
                let companyData = res.data.companyDetail[0];
                
                setstate(companyData);
                
            }



        }).catch((e) => {


        });
    }
    const getkyc_settings = () => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/aadhar_kyc_settings`;
        let sendData = {};
        // console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
              console.log("kycsettings ", res);
              setaadharstate(res.data.aadhar_settings)
              setgststate(res.data.gst_settings)
    }).catch((e) => {


        });
    }
    // const handleChange = (e,carrier_id) =>{
    //     console.log("id---> ", carrier_id);
    
    //     console.log("e---> ", e.target.name);
    //     console.log("e----> ", e.target.checked);
    //     setSelectedCarrier({...selectedCarrier, label_status:(e.target.checked)?1:0})
    //     changeStatus({carrier_id:carrier_id,label_status:(e.target.checked)? 1 : 0})
    // }
    const onChangeHandlerGeneral = (e) => {
        // console.log("e general name ",e.target.name);
          console.log("e general value ",e.target.type);
         const newdata = { ...state };
         console.log("state === " , newdata)
         if(e.target.name=="aadhar_verification"){
             newdata[e.target.name] = (e.target.checked)?1:0
           
           
            //  companySettingUpdate()
         }
         else{
            newdata[e.target.name] = (e.target.checked)?1:0
            setstate(newdata);
            // companySettingUpdate()
         }
        
         setstate(newdata);
     }

     const companySettingUpdate = (UpdateData) => {
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/edit_company_api_kyc_settings`;
        let sendData = { company_id: state.company_id, aadhar_verification: state.aadhar_verification , gst_verification:state.gst_verification };
        console.log("sendData   ", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            //console.log("res ", res); 
            if(res.data.status=="success"){
                // navigate('/system_settings')   
                getCompanyDetail()
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  
                  Toast.fire({
                    background:"#206bc4",
                    type: 'success',
                    title: "Update Sucessfully",
                    color:"white"
                  });

            }       
           

        }).catch((e) => {

        });
    }
  return (
    <div>

 <Sub_header/>
<div style={{marginLeft:"15px",marginRight:"15px"}}>
<section>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div class="d-block mb-4 mb-md-0">
            <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                    <li class="breadcrumb-item">
                        <a href="#">
                            <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">KYC Settings</li>
                </ol>
            </nav>
            <h2 class="h4">Customer KYC Settings</h2>
        </div>
        <div class="btn-toolbar mb-2 mb-md-0">
            {/* <!-- Additional buttons or toolbar content can go here --> */}
        </div>
    </div>
</section>

<section class="mb-3">
    <div class="row">
        <div class="col-12 col-md">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                <a class="nav-link active" data-bs-toggle="tab" href="#home">Modules</a>
                </li>
                <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#menu1">Aadhar Card Verification</a>
                </li>
                <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#menu2">GST ID Verification</a>
                </li>
            </ul>
            
          <div class="tab-content">
            <div class="tab-pane  active" id="home">
            <div class="card card-body border-0 shadow mb-4 mt-1">
                <h5>Module Settings</h5>
                <small>Set the module you want to keep enable on the custoemr side for verifiation.</small>
                {/* <form> */}
                    <div class="row">
                        <div class="col-md-1">
                            <div class="">
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" id="mySwitch" name="aadhar_verification" checked={state.aadhar_verification} onChange={(e)=>onChangeHandlerGeneral(e)}  />
                                    <label class="form-check-label" for="mySwitch"></label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md">
                            <label for="email">Aadhar Card Verification </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-1">
                            <div class="">
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" id="mySwitch" name="gst_verification" checked={state.gst_verification} onChange={(e)=>onChangeHandlerGeneral(e)}  />
                                    <label class="form-check-label" for="mySwitch"></label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md ">
                            <label for="email">Company GST ID Verification </label>
                        </div>
                    </div>
                    
                    <div class="mt-3">
                        <button class="btn btn-gray-800 mt-2 animate-up-2" onClick={(e)=>companySettingUpdate()}>Save</button>
                    </div>
                {/* </form> */}
            </div>
            </div>
            <div class="tab-pane fade" id="menu1">
                <div class="card card-body border-0 shadow mb-5 mt-1">
                    {/* <form> */}
                    {aadharstate.map((sub)=>(
                         <>
                        <div class="row kyc_settings">
                            <div class="row">
                                <div class="col-12 col-md-2">
                                    <label class="custom-radio">
                                        <input type="radio" name="status"   checked={sub.status==true} />
                                        <div class="radio-button">
                                            <div class="status-text"></div>
                                        </div>
                                        <div class="radio-label">{sub.name}</div>
                                    </label>
                                </div>
                                <div class="col-12 col-md">
                                    <div class="col-12 col-md">
                                        <div>
                                            <img src="./assets/img/cashfree_logo.png" class="img-fluid img-thumbnail" />
                                        </div>
                                        <div class="row">
                                            <div class="col-6 mb-3">
                                                <label for="email">Client secret ID</label>
                                                <div>
                                                    <input class="form-control" id="first_name" type="text" placeholder="Enter Client secret ID" value={sub.client_id} />
                                                </div>
                                            </div>
                                            <div class="col-6 mb-3">
                                                <label for="email">Client secret key</label>
                                                <div>
                                                    <input class="form-control" id="first_name" type="text" placeholder="Enter Client secret key"  value={sub.client_secret_key}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                         <hr />
                        </>
    ))}
                       
                        
    
    
                        {/* <!-- Save button --> */}
                        <div class="mt-3">
                            <button class="btn btn-gray-800 mt-2 animate-up-2" type="submit">Save</button>
                        </div>
                    {/* </form> */}
                </div>
            </div>
            <div class="tab-pane  fade" id="menu2">
                <div class="card card-body border-0 shadow mb-5 mt-1">
                    {/* <form> */}
                    {gststate.map((sub)=>(
                        <>
                        <div class="row kyc_settings">
                            <div class="row">
                                <div class="col-12 col-md-2">
                                    <label class="custom-radio">
                                        <input type="radio" name="radioGroup" value="on" checked />
                                        <div class="radio-button">
                                            <div class="status-text"></div>
                                        </div>
                                        <div class="radio-label">{sub.name}</div>
                                    </label>
                                </div>
                                <div class="col-12 col-md">
                                    <div class="col-12 col-md">
                                        <div>
                                            <img src="./assets/img/cashfree_logo.png" class="img-fluid img-thumbnail" />
                                        </div>
                                        <div class="row">
                                            <div class="col-6 mb-3">
                                                <label for="email">Client secret ID</label>
                                                <div>
                                                    <input class="form-control" id="first_name" type="text" placeholder="Enter Client secret ID" value={sub.client_id} />
                                                </div>
                                            </div>
                                            <div class="col-6 mb-3">
                                                <label for="email">Client secret key</label>
                                                <div>
                                                    <input class="form-control" id="first_name" type="text" placeholder="Enter Client secret key" value={sub.client_secret_key} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <hr />
                        </>
                        ))}
    
                        {/* <!-- Save button --> */}
                        <div class="mt-3">
                            <button class="btn btn-gray-800 mt-2 animate-up-2" >Save</button>
                        </div>
                    {/* </form> */}
                </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3">
            <h6>KYC Gateway Settings</h6>
            <p>
                These settings are intended for use within the KYC (Know Your Customer) module, where clients can verify their Aadhar or GST numbers through the Gateway.
            </p>
            <p>
                Exercise caution when updating any fields to avoid disrupting the module's functionality. Please ensure you verify the changes before finalizing them.
            </p>
        </div>
    </div>
</section>

</div>
{/* <?php
include('footer.php');
?> */}

    </div>
  )
}

export default Customer_kyc_settings
