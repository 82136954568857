import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import { useNavigate, useParams } from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination';
import ReactLoading from 'react-loading';
const Missing_pincodes = () => {
    const[state,setState] = React.useState([])
    const[isLoading , setIsLoading] = React.useState(true)
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
    React.useEffect(()=>{
        getList()
    },[])
    const pincode_test =()=>{
       
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/pincodes_test`;
        let sendData = {};
       axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
        getList()
        }).catch((e) => {
            console.log("e == " , e)
        });
    }
    const getList = () => {

        setIsLoading(true)
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/missing_pincodes_file_list`;
        let sendData = {};
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            setIsLoading(false)
            setState(res.data.output)
          }).catch((e) => {
            setIsLoading(false)

        });
    }

  return (
    <div>
         {/* <Sub_header /> */}
         <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/>

       <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                        <div className="d-block mb-4 mb-md-0">
                            <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                               
                            </nav>
                            <h2 className="h4">Missing Pincode Report </h2>
                        </div>
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="input-group me-2 me-lg-3 fmxw-400">
                                {/* <span className="input-group-text"> */}
                                    {/* <svg className="icon icon-xs" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" onClick={(e) => filter(e)}>
                                        <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                                    </svg> */}
                                {/* </span> */}
                                {/* <input type="text" className="form-control" placeholder="Search Pincodes" name="stitle" value={searchfilters.searchtitle} onChange={(e) => stateHandler(e)} /> */}
                                <a onClick={()=>pincode_test()} class="btn btn-sm btn-gray-800 d-inline-flex align-items-center ms-2" data-bs-toggle="modal" data-bs-target="#import_pincode_special">
          
            Test Pincodes
        </a>
                            </div>
                           
                        </div>
                    </div>

                
                    {(isLoading) ?
                <div style={{ backgroundColor: "#f1f5f9", zIndex: 2, height: "100%", width: "100%", position: "absolute", opacity: "0.4" }}>
                    <div style={{ left: "47%", top: "45%", position: "absolute" }}>
                        <ReactLoading type={"spin"} color={"#000000"} height={300} width={89} />
                    </div>
                </div> : 
        
        
        <div class="card card-body border-0 shadow table-wrapper table-responsive">
            <table class="table table-hover carr_list_ad">
                <thead>
                    <tr>
                        <th class="border-gray-200">#</th>
                        <th class="border-gray-200">#ID</th>
                        <th class="border-gray-200">Status</th>
                           </tr>
                </thead>
              
                <tbody>
                    {state.map((sub, index)=>(
                   <tr>
                    <td>{index+1}</td>
                    <td>{sub.record_id}</td>
                    <td>{sub.status==2?"Running":"Completed"}</td>
                   
                   </tr>
                   ))}
                </tbody>
                
            </table>
            {/* <TablePagination
                            component="div"
                            rowsPerPageOptions={[5, 10]}
                            count={otherStates.total_count}
                            page={otherStates.page}
                            onPageChange={handlePageChange}
                            rowsPerPage={otherStates.rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}

                        /> */}
        </div> 
}   

</section>




    </div>
  )
}

export default Missing_pincodes
